import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import toast from 'react-hot-toast';

// Loader Components
import showLoader from '@components/loader';

export const getPreviewDetails = createAsyncThunk('previewSlice/getPreviewDetails', async (cardId) => {
    try {
        let response = await axios.get('/card/profile-detail/' + cardId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        return Promise.reject(err, 'err');
    }
});
export const getVCFDetails = createAsyncThunk('previewSlice/getVCFDetails', async (cardId) => {
    try {
        let response = await axios.get('/vcf/' + cardId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const previewSlice = createSlice({
    name: 'previewSlice',
    initialState: {
        data: {},
        VCFData: {}
    },

    extraReducers: (builder) => {
        builder.addCase(getPreviewDetails.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getVCFDetails.fulfilled, (state, action) => {
            state.VCFData = action?.payload;
            return;
        });
    }
});

export default previewSlice.reducer;
