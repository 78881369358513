import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import toast from 'react-hot-toast';

// Loader Components
import showLoader from '@components/loader';

export const getSocialMediaMaster = createAsyncThunk('socialMedia/getSocialMediaMaster', async () => {
    try {
        let response = await axios.get('/social-media-master', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMySocialMedia = createAsyncThunk('socialMedia/getMySocialMedia', async () => {
    try {
        let response = await axios.get('/social-media', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSocialMedia = createAsyncThunk('socialMedia/createSocialMedia', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/social-media', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Social Media added successfully.');
        dispatch(getMySocialMedia());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSocialMedia = createAsyncThunk('socialMedia/updateSocialMedia', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/social-media/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Social Media updated succesfully.');
        dispatch(getMySocialMedia());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSocialMedia = createAsyncThunk('socialMedia/deleteSocialMedia', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/social-media/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Social Media deleted successfully.');
        dispatch(getMySocialMedia());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updatSocialMediaStatus = createAsyncThunk('socialMedia/updatSocialMediaStatus', async (id, { dispatch }) => {
    try {
        // showLoader(true);

        let response = await axios.put('/social-media/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getMySocialMedia());
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSocialMediaPriority = createAsyncThunk('socialMedia/updateSocialMediaPriority', async (data, { dispatch }) => {
    try {
        let response = await axios.put('social-media/priority', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(getMySocialMedia());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const socialMedia = createSlice({
    name: 'socialMedia',
    initialState: {
        data: [],
        mySocialMedia: [],
        option: [],
        selectedData: {}
    },

    extraReducers: (builder) => {
        builder.addCase(getSocialMediaMaster.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getMySocialMedia.fulfilled, (state, action) => {
            state.mySocialMedia = action?.payload;
            return;
        });
    }
});

export default socialMedia.reducer;
