import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import toast from 'react-hot-toast';

// Loader Components
import showLoader from '@components/loader';

export const getBankDetails = createAsyncThunk('bankSlice/getBankDetails', async () => {
    try {
        let response = await axios.get('profile/customer-bank-detail', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createBankDetails = createAsyncThunk('bankSlice/createBankDetails', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/customer-bank-detail', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bank Details added successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateBankDetails = createAsyncThunk('bankSlice/updateBankDetails', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/customer-bank-detail/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bank Details updated succesfully.');
        showLoader(false);
        dispatch(getBankDetails());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updatBankStatus = createAsyncThunk('bankSlice/updatBankStatus', async (id, { dispatch }) => {
    try {
        // showLoader(true);

        let response = await axios.put('/customer-bank-detail/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(getBankDetails());
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBankDetails = createAsyncThunk('bankSlice/deleteBankDetails', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/customer-bank-detail/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bank Details deleted successfully.');

        showLoader(false);

        dispatch(getBankDetails());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const bankSlice = createSlice({
    name: 'bankSlice',
    initialState: {
        data: {}
    },

    extraReducers: (builder) => {
        builder.addCase(getBankDetails.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default bankSlice.reducer;
