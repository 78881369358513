// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import socialMedia from '../views/Home/store';
import documentStore from '../views/Home/store/documentStore';
import bankStore from '../views/Home/store/bankStore';
import previewStore from '../views/Home/store/previewStore';
import customerProfile from '../views/ManageProfile/store';
import personalDetailSlice from '../views/UserProfile/Profile/store';
const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    socialMedia,
    bankStore,
    previewStore,
    documentStore,
    customerProfile,
    personalDetailSlice
};

export default rootReducer;
