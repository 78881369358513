import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import toast from 'react-hot-toast';

// Loader Components
import showLoader from '@components/loader';

export const getDocuments = createAsyncThunk('documentSlice/getDocuments', async () => {
    try {
        let response = await axios.get('/customer-document', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDocument = createAsyncThunk('documentSlice/createDocument', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/customer-document', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Document added successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDocuments = createAsyncThunk('documentSlice/updateDocuments', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/customer-document/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Document updated succesfully.');
        dispatch(getDocuments());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDocuments = createAsyncThunk('documentSlice/deleteDocuments', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/customer-document/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Document deleted successfully.');
        dispatch(getDocuments());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updatDocumentStatus = createAsyncThunk('documentSlice/updatDocumentStatus', async (id, { dispatch }) => {
    try {
        // showLoader(true);

        let response = await axios.put('/customer-document/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(getDocuments());
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDocumentPriority = createAsyncThunk('documentSlice/updateDocumentPriority', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/customer-document/priority', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(getDocuments());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const documentSlice = createSlice({
    name: 'documentSlice',
    initialState: {
        data: []
    },

    extraReducers: (builder) => {
        builder.addCase(getDocuments.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default documentSlice.reducer;
