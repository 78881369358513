import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import toast from 'react-hot-toast';

// Loader Components
import showLoader from '@components/loader';

export const getPersonalDetail = createAsyncThunk('personalDetailSlice/getPersonalDetail', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/customer-personal-detail/is-default', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        // toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createProfileDetail = createAsyncThunk('personalDetailSlice/createProfileDetail', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/customer-personal-detail', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Profile Detail added successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateProfileDetail = createAsyncThunk('personalDetailSlice/updateProfileDetail', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/customer-personal-detail/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Profile Detail updated succesfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeProfileImage = createAsyncThunk('personalDetailSlice/removeProfileImage', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/profile/remove-image/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Profile Image removed succesfully.');
        dispatch(getPersonalDetail());
        showLoader(false);

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeBannerImage = createAsyncThunk('personalDetailSlice/removeBannerImage', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/banner/remove-image/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Banner Image removed succesfully.');
        dispatch(getPersonalDetail());
        showLoader(false);

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const personalDetailSlice = createSlice({
    name: 'personalDetailSlice',
    initialState: {
        selectedData: {}
    },

    extraReducers: (builder) => {
        builder.addCase(getPersonalDetail.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default personalDetailSlice.reducer;
